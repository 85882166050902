<template>
  <div>
    <Header />
    <div class="body">
      <!-- titlebar -->
      <div id="titlebar">
        <div class="container">
          <!-- breadcrumbsV2 -->
          <nav id="breadcrumbsV2" class="pt-4">
            <ul>
              <li>
                <router-link to="/">الرئيسية</router-link>
              </li>
              <li>مركز المساعده</li>
            </ul>
            <h1>مركز المساعده</h1>
          </nav>
          <nav id="contactButtons">
            <div class="contactContainer">
              <div
                @click="contact(card.type)"
                v-for="card in contactCards"
                :key="card.type"
                class="contactCard"
              >
                <div class="contactCardLogo">
                  <img :src="card.icon" alt="Card Icon" />
                </div>
                <div class="contactCardContent">
                  <h1 class="title">
                    {{ card.title }}
                  </h1>
                  <p class="desc">
                    {{ card.desc }}
                  </p>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <!-- Container -->
      <div id="test" class="container actions">
        <div v-if="false" class="box-1 right">
          <div class="title">
            <p class="titleContent">المساعدات السابقه</p>
          </div>
          <b-tabs
            content-class="rightTabs"
            v-model="rightTabIndex"
            nav-class="tabs-header"
          >
            <b-tab class="step">
              <div class="container">
                <div class="content">
                  <p>لا يوجد شكاوي سابقه,</p>
                  <a href="" @click.prevent="loginPageRoute"
                    >يرجي تسجيل الدخول أولا</a
                  >
                </div>
              </div>
            </b-tab>
            <b-tab>
              <div v-if="lastComplains.length > 0">
                <div
                  class="complainContainer mt-5"
                  v-for="(complain, i) in lastComplains"
                  :key="i"
                >
                  <div class="logo ml-2 mr-4">
                    <img
                      :src="
                        complain.hectarType == 'hectar'
                          ? hectarLogo
                          : hectarPlusLogo
                      "
                      alt="Site Logo"
                    />
                  </div>
                  <div class="content">
                    <h1>مركز المساعده</h1>
                    <p>{{ complain.content }}</p>
                  </div>
                </div>
              </div>
              <div v-else class="container">
                <div class="content">
                  <p>لا يوجد شكاوي سابقه,</p>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        <div class="box-2">
          <div class="title">
            <div class="titleLogo">
              <img
                src="@/assets/images/svgs/hectar.svg"
                alt="Site Logo"
                loading="lazy"
              />
            </div>
            <p class="titleContent">مركز المساعده</p>
          </div>
          <b-tabs
            content-class="leftTabs"
            v-model="leftTabIndex"
            nav-class="tabs-header"
          >
            <!-- Bootstrap Vue -->
            <div v-if="step" class="step1">
              <div class="logoContainer">
                <img
                  src="@/assets/images/svgs/hectar2X.svg"
                  alt="Site Logo"
                  loading="lazy"
                />
                <h1>مركز المساعده</h1>
                <div v-if="firstTime">
                  <p>
                    أهلا ومرحبا بك, سعيدين لتواصلك معنا في مركز مساعده هكتار,
                    <br />
                    ابدأ الأن حتي تتواصل معنا
                  </p>
                  <button
                    v-if="loggedIn == 'true'"
                    class="start"
                    @click="startClick"
                  >
                    ابدأ الأن
                  </button>
                  <button v-else class="start" @click="loginPageRoute">
                    قم بتسجيل الدخول أولا
                  </button>
                </div>
                <div v-else>
                  <p>“شكرا لك، ونتمنى نكون قد قمنا بخدمتك بالطريقة الأمثل“</p>
                  <button class="start" @click="startClick">
                    ابدأ من جديد
                  </button>
                </div>
              </div>
            </div>
            <div v-else class="step2">
              <div v-if="counter >= 1" class="chatContainer mt-5">
                <div class="logo mr-4">
                  <img :src="hectarLogo" alt="Site Logo" />
                </div>
                <div class="content">
                  <p>أهلا بيك ممكن تقولنا بتواجه <b>مشكلتك فين</b> ؟</p>
                  <div class="chatInnerContainer">
                    <button
                      :disabled="counterClick < 1 ? false : true"
                      @click="complainCreation('hectar', 'hectarType')"
                      class="hectar"
                    >
                      <img
                        src="@/assets/images/svgs/hectarChoice.svg"
                        alt="Site Logo"
                        loading="lazy"
                      />
                    </button>
                    <button
                      :disabled="counterClick < 1 ? false : true"
                      @click="complainCreation('hectarPlus', 'hectarType')"
                      class="hectar+"
                    >
                      <img
                        src="@/assets/images/svgs/hectarChoicePlus.svg"
                        alt="Site Logo"
                        loading="lazy"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="complain.hectarType"
                class="chatContainer left mt-5 ml-5 mb-4"
              >
                <div class="content">
                  <div class="chatInnerContainer">
                    <p>
                      {{
                        complain.hectarType == 'hectar'
                          ? 'تطبيق هكتار'
                          : 'تطبيق هكتار بلس'
                      }}
                    </p>
                  </div>
                </div>
                <div class="logo">
                  <img :src="hectarLogo" alt="Site Logo" />
                </div>
              </div>
              <div
                v-if="showComplainType && counter >= 2"
                class="chatContainer bottom"
              >
                <div class="logo mr-4">
                  <img :src="hectarLogo" alt="Site Logo" />
                </div>
                <div class="content">
                  <p>ممكن تختار ايه <b>القسم</b> اللي فيه المشكله ؟</p>
                  <div class="chatInnerContainer">
                    <button
                      v-for="(department, i) in departments"
                      :key="i"
                      :disabled="counterClick < 2 ? false : true"
                      @click="complainCreation(department.title, 'type')"
                    >
                      <img :src="department.icon" alt="Department Icon" />
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="complain.department"
                class="chatContainer left mt-5 ml-5 mb-4"
              >
                <div class="content">
                  <div class="chatInnerContainer">
                    <p>
                      {{ complain.department }}
                    </p>
                  </div>
                </div>
                <div class="logo">
                  <img :src="hectarLogo" alt="Site Logo" />
                </div>
              </div>
              <div
                v-if="showExistedComplains && counter >= 3"
                class="chatContainer problems mt-5"
              >
                <div class="logo mr-4">
                  <img :src="hectarLogo" alt="Site Logo" />
                </div>
                <div class="content">
                  <p>هل مشكلتك من <b>المشاكل</b> دي ؟</p>
                  <div class="problemsList">
                    <ul>
                      <li v-for="(problem, i) in problems" :key="i">
                        <button
                          :disabled="counterClick < 3 ? false : true"
                          @click="complainCreation(problem, 'desc')"
                        >
                          {{ problem.question }}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                v-if="question.length"
                class="chatContainer left mt-5 ml-5 mb-4"
              >
                <div class="content">
                  <div class="chatInnerContainer">
                    <p>
                      {{ question }}
                    </p>
                  </div>
                </div>
                <div class="logo">
                  <img :src="hectarLogo" alt="Site Logo" />
                </div>
              </div>
              <div
                v-if="startAgain && counter >= 4 && answer != 'مشكله أخري'"
                class="chatContainer answer mt-5"
              >
                <div class="logo mr-4">
                  <img :src="hectarLogo" alt="Site Logo" />
                </div>
                <div class="content">
                  <p>
                    <b>{{ answer }}</b>
                  </p>
                </div>
              </div>
              <div
                v-if="startAgain && counter >= 4"
                class="chatContainer problems mt-5"
              >
                <div class="logo mr-4">
                  <img :src="hectarLogo" alt="Site Logo" />
                </div>
                <div v-if="answer != 'مشكله أخري'" class="content">
                  <p>شكرا لإستخدامك تطبيقات هكتار</p>
                  <p><b>- هل لديك أسئله أخري</b></p>
                  <div class="yesOrNo">
                    <button @click="resetComplain()">نعم</button>
                    <button @click="backToStartPage()">لا</button>
                  </div>
                </div>
                <div v-else class="content">
                  <p>
                    الرجاء تعبئة النموذج التالي وارساله لنا لمتابعة وحل المشكلة
                  </p>
                  <form>
                    <input
                      v-model="complain.title"
                      type="text"
                      placeholder="عنوان المشكله"
                    />
                    <textarea
                      v-model="complain.desc"
                      rows="10"
                      placeholder="وصف المشكله ...."
                    ></textarea>
                    <p v-if="formCheck">* من فضلك املأ الحقول بشكل صحيح</p>
                    <button @click.prevent="onSubmit()">إرسال</button>
                  </form>
                </div>
              </div>
              <div v-if="typing" class="typeing mt-5">
                <div class="logo mr-4">
                  <img :src="hectarLogo" alt="Site Logo" />
                </div>
                <div class="content">
                  <div class="balls">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </b-tabs>
        </div>
      </div>
      <!-- Container / End -->
    </div>

    <!-- ---------- -->
    <!-- Call modal -->
    <!-- ---------- -->
    <CallModal
      v-if="toggleCallModal"
      :status="toggleCallModal"
      @closeModal="toggleCallModal = false"
    />
  </div>
</template>

<script>
import Header from '@/components/header/helpCenterHeader.vue'
import CallModal from '@/components/modals/CallModal.vue'

export default {
  components: {
    Header,
    CallModal
  },
  data() {
    return {
      step: true,
      typing: true,
      counter: 0,
      counterClick: 0,
      showComplainType: false,
      showExistedComplains: false,
      startAgain: false,
      complain: {},
      question: '',
      answer: '',
      formCheck: false,
      hectar_international_phone_number: '05299665588',
      loggedIn: '',
      rightTabIndex: 0,
      leftTabIndex: 0,
      firstTime: true,
      hectarLogo: require('@/assets/images/svgs/hectar.svg'),
      hectarPlusLogo: require('@/assets/images/svgs/hectar.svg'),
      contactCards: [
        {
          // feature for future
          title: 'طلب اتصال',
          icon: require('@/assets/images/svgs/call.svg'),
          desc: 'املأ البيانات وسنتواصل معك',
          type: 'phone'
        },
        {
          title: 'تواصل واتس',
          icon: require('@/assets/images/svgs/WhatsApp.svg'),
          desc: 'التواصل متاح من الساعه 9 لـ 5',
          type: 'whatsapp'
        }
      ],
      lastComplains: [
        {
          hectarType: 'hectar',
          content: 'تطبيق هكتار, رسائل العملاء تطبيق هكتار'
        },
        {
          hectarType: 'hectar+',
          content: 'تطبيق هكتار, رسائل العملاء تطبيق هكتار'
        }
      ],
      problems: [
        {
          question: 'ما هي المده التي يظل العقار المدرج متاحا للعرض في منصه ؟',
          answer: 'اجابه 1'
        },
        {
          question: 'كيف يمكنني تحويل حاله العقار من منتهي إلي نشط ؟',
          answer: 'اجابه 2'
        },
        {
          question:
            'عنوان السؤال هنا كيف يمكنني تحويل حاله العقار من نشط إلي منتهي ؟',
          answer: 'اجابه 3'
        },
        {
          question:
            'ما هي المده التي يظل فيها العقار المدرج متاحا للعرض في المنصه ؟',
          answer: 'اجابه 4'
        },
        {
          question: 'مشكله أخري',
          answer: 'مشكله أخري'
        }
      ],
      departments: [
        {
          title: 'أسئله عامه',
          icon: require('@/assets/images/svgs/questions.svg')
        },
        {
          title: 'أسئله عامه 1',
          icon: require('@/assets/images/svgs/Group 3795.svg')
        },
        {
          title: 'أسئله عامه 2',
          icon: require('@/assets/images/svgs/Group 3793.svg')
        },
        {
          title: 'أسئله عامه 3',
          icon: require('@/assets/images/svgs/Group 3791.svg')
        },
        {
          title: 'أسئله عامه 4',
          icon: require('@/assets/images/svgs/Group 3789.svg')
        },
        {
          title: 'أسئله عامه 5',
          icon: require('@/assets/images/svgs/Group 3787.svg')
        }
      ],
      toggleCallModal: false
    }
  },
  computed: {
    getRightTab() {
      return this.loggedIn == 'true' ? 1 : 0
    }
  },
  methods: {
    checkForm() {
      if (
        this.complain.hectarType &&
        this.complain.department &&
        this.complain.title &&
        this.complain.desc
      ) {
        return true
      }
    },
    startClick() {
      this.step = false
      this.typing = true
      this.counter = 0
      this.typingDots()
    },

    typingDots() {
      setTimeout(() => {
        this.typing = false
        this.counter++
      }, 1000)
    },
    complainCreation(val, type) {
      if (type == 'hectarType') {
        this.complain.hectarType = val
        this.showComplainType = true
      } else if (type == 'type') {
        this.complain.department = val
        this.showExistedComplains = true
      } else {
        this.answer = val.answer
        this.question = val.question
        this.startAgain = true
      }
      this.typing = true
      this.typingDots()
      this.counterClick++
    },

    resetComplain() {
      this.showComplainType =
        this.showExistedComplains =
        this.startAgain =
          false
      this.complain = {}
      this.counterClick = 0
      this.typing = true
      this.typingDots()
      this.counter = 0
      this.answer = this.question = ''
    },
    backToStartPage() {
      this.resetComplain()
      this.step = true
      this.firstTime = false
      this.formCheck = false
    },
    contact(type) {
      if (type !== 'phone') {
        const currentTime = new Date()
        if (
          currentTime.getHours() >= 9 &&
          currentTime.getHours() <= 16 &&
          currentTime.getMinutes() <= 59
        ) {
          // TODO get name
          return window.open(
            `https://api.whatsapp.com/send?phone=${this.hectar_international_phone_number}&text=مرحباً ${this.showName}`,
            '_blank'
          )
        }
      } else {
        this.toggleCallModal = true
      }
    },
    onSubmit() {
      this.checkForm() ? this.backToStartPage() : (this.formCheck = true)
    },
    loginPageRoute() {
      this.$router.push('/auth/login')
    }
  },
  created() {
    this.loggedIn = localStorage.getItem('logged_in')
    this.rightTabIndex = this.loggedIn == 'true' ? 1 : 0
  }
}
</script>

<style lang="scss">
@import '@/styles/helpCenter/helpCenter';
</style>
