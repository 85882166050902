<template>
  <b-modal
    v-model="modalStatus"
    id="callModal"
    class="px-0 py-0"
    title="طلب اتصال"
    hide-footer
    hide-header
    centered
  >
    <!-- Header -->
    <template>
      <div
        class="px-3 py-2 cardTitle d-flex align-items-center justify-content-between mb-3"
      >
        <p class="weight-600 font-16 pt-1">طلب اتصال</p>
        <button
          @click="closeModal"
          type="button"
          aria-label="Close"
          class="close close-modal-btn initial"
        >
          <span class="hi-close"></span>
        </button>
      </div>
    </template>

    <template>
      <b-form class="px-3 py-2" @submit.prevent="onSubmit">
        <!-- Name -->
        <label for="name" class="weight-600">الاسم:</label>
        <b-form-input
          class="mb-3"
          id="name"
          v-model="userInfo.name"
          autocomplete="off"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="ادخل الاسم"
          trim
        />

        <!-- Phone number -->
        <label for="phoneNumber" class="weight-600">رقم الهاتف:</label>
        <b-form-input
          :readonly="!!$route.query.id"
          class="mb-3"
          id="phoneNumber"
          v-model="userInfo.phoneNumber"
          autocomplete="off"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="ادخل رقم الهاتف"
          @input.native="preventText"
          trim
        />
        <span
          v-if="userInfo.phoneNumber && !isPhoneNumberValid"
          class="invalid-feedback d-block"
        >
          صيغة رقم الهاتف غير صحيحة
        </span>
        <b-row>
          <!-- Select date -->
          <b-col lg="6" class="mb-3">
            <label class="weight-600">اختر التاريخ</label>
            <div class="mb-2 datepicker">
              <b-form-datepicker
                v-model="userInfo.date"
                size="sm"
                locale="ar"
                :min="min"
                placeholder="اختر التاريخ"
                class="direction-ltr"
              >
              </b-form-datepicker>
              <img :src="dateIcon" alt="icon" height="26px" />
            </div>
          </b-col>

          <!-- Select time -->
          <b-col lg="6">
            <label class="weight-600">اختر الوقت</label>
            <Multiselect
              v-model="userInfo.time"
              :show-labels="false"
              placeholder="اختر الوقت"
              :options="times"
              track-by="title"
              label="title"
              :allow-empty="false"
            />
          </b-col>
        </b-row>

        <!-- Submit -->
        <b-button
          :disabled="!disabledSubmit"
          type="submit"
          class="btn-submit mt-5 mb-2"
        >
          ارسال
          <img class="mr-3" :src="submitIcon" alt="icon" height="20px" />
        </b-button>
      </b-form>
    </template>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { userService } from '@/services'

export default {
  props: {
    status: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userInfo: {
        name: this.$route.query.name ?? '',
        phoneNumber: this.$route.query.phone?.replace(/^966/, '0') ?? '',
        time: '',
        date: ''
      },
      times: [
        { title: '٩ص الي ١٠ص', value: '9' },
        { title: '١٠ص الي ١١ص', value: '10' },
        { title: '١١ص الي ١٢م', value: '11' },
        { title: '١٢م الي ١م', value: '12' },
        { title: '١م الي ٢م', value: '12' },
        { title: '٢م الي ٣م', value: '12' },
        { title: '٣م الي ٤م', value: '12' },
        { title: '٤م الي ٥م', value: '12' },
        { title: '٥م الي ٦م', value: '12' }
      ],
      dateIcon: require('@/assets/images/svgs/calendar.svg'),
      submitIcon: require('@/assets/images/svgs/arrow-submit.svg')
    }
  },
  components: {
    Multiselect
  },
  computed: {
    min() {
      const date = new Date()
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `${year}-${month}-${day}`
    },
    modalStatus: {
      get() {
        return this.status
      },
      set() {
        this.closeModal()
      }
    },
    isPhoneNumberValid() {
      return /^(05)([0-9]{8})$/.test(this.userInfo.phoneNumber)
    },
    disabledSubmit() {
      const { name, time, date } = this.userInfo
      return name && this.isPhoneNumberValid && time && date
    }
  },
  methods: {
    preventText(e) {
      const { target } = e
      if (!/^\d+$/.test(target.value)) target.value = ''
    },
    closeModal() {
      this.$emit('closeModal')
    },
    async onSubmit() {
      const { name, phoneNumber, date, time } = this.userInfo
      try {
        let body = {}
        const id = this.$route.query.id

        if (id) {
          body = {
            name,
            phoneNumber,
            user: id,
            date,
            time: +time.value
          }
        } else {
          body = { name, phoneNumber, date, time: +time.value }
        }

        await userService.requestCall({
          ...body,
          phoneNumber: body.phoneNumber.replace('0', '966')
        })

        this.$toasted.show('تم ارسال طلب الاتصال بنجاح', {
          duration: 7000,
          type: 'success'
        })
        this.closeModal()
      } catch (err) {
        if (err.response.data.message.includes('exists')) {
          this.$toasted.show('تم ارسال طلب الاتصال من قبل', {
            type: 'error',
            duration: 5000,
            icon: 'hi-close'
          })
          this.closeModal()
        } else {
          this.$toasted.show('حدث خطا يرجى المحاوله مره اخرى', {
            type: 'error',
            duration: 5000,
            icon: 'hi-close'
          })
        }
      }
    }
  }
}
</script>

<style>
#callModal___BV_modal_body_ {
  padding: 0px !important;
}
/* Style will override on BOOTSTRAP */
input,
input[type='text'] {
  height: 40px;
  border-radius: 10px;
}

.initial {
  position: initial;
}

.weight-600 {
  font-weight: 600;
}

.direction-ltr {
  direction: ltr;
  border: none;
}

div.multiselect__tags {
  min-height: 3px;
  height: 40px;
  border-radius: 10px;
}

div.multiselect__select {
  height: 40px;
}

span.multiselect__placeholder {
  font-size: 12px;
  line-height: 38px;
}

.multiselect--above .multiselect__content-wrapper,
div.multiselect__tags input[type='text'] {
  font-size: 12px;
}

span.multiselect__single {
  line-height: 35px;
  font-size: 12px;
}

.dropdown-menu {
  left: 50% !important;
  transform: translate3d(-50%, -90%, 0) !important;
}

.form-control.form-control {
  line-height: 33px;
}

.b-form-btn-label-control {
  height: 40px !important;
  border-radius: 10px;
}

.b-form-btn-label-control.form-control > label.form-control-sm {
  font-size: 12px;
}

.btn.btn-sm.h-auto {
  display: none;
}

.datepicker {
  display: flex;
  align-items: center;
  border: 1px solid #dde1e5;
  border-radius: 10px;
  padding: 0 7px;
}

.btn-submit {
  min-height: 32px !important;
  background-color: #3cb97d;
  margin-inline-start: auto;
  border-radius: 10px;
  padding: 0 30px;
}

.btn-secondary,
.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: #3eb97dd4 !important;
}

.cardTitle {
  box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.07);
}
</style>
