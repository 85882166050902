<template>
  <header id="headerContainer">
    <!-- Header -->
    <div class="headerWrapper">
      <!-- <div id="header" :class="{sticky: scrollPosition > (88 + 138) && this.$route.name == 'property'}"> -->
      <div id="headerr">
        <!-- <div id="header"> -->
        <div class="container">
          <div class="headerInner">
            <div class="rightContent" id="">
              <router-link :to="{ name: 'home' }">
                <img src="../../assets/images/svgs/helpCenterLogo.svg" alt="Help Center Logo" loading="lazy"/>
              </router-link>
            </div>
            <!-- Left Side Content -->
            <div class="centerContent">
              <!-- Main Navigation -->
              <ul id="navigation">
                <li>
                  <router-link
                    :to="{ name: 'home' }"
                    exact
                    class="centerContent-item"
                    >الرئيسية
                  </router-link>
                </li>
                <!-- <li>
                    <router-link 
                        class="centerContent-item" 
                        :to="{  name: 'contact'}">تواصل معنا 
                    </router-link>
                </li> -->
              </ul>
              <!-- Main Navigation / End -->
            </div>
            <div class="leftContent">
              <div v-if="loggedIn">
                <div class="d-flex nameContainer">
                  <h6 v-if="currentUserName" class="substringName">
                    {{ currentUserName ? subString(currentUserName) : '' }}
                  </h6>
                  <div class="left">
                    <p class="hi">حياك الله</p>
                    <h5 v-if="currentUserName" class="name">
                      {{ currentUserName }}
                    </h5>
                    <span v-else>-</span>
                  </div>
                </div>
              </div>
              <b-button v-else variant="primary" @click="loginPageRoute">
                تسجيل دخول
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Header / End -->
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      loggedIn: '',
      currentUserName: ''
    }
  },
  methods: {
    loginPageRoute() {
      this.$router.push('/auth/login')
    },
    subString(val) {
      let x = val.replace(' ', ',')
      let arr = x.split(',')
      let str = arr.map(str => {
        return str.substring(0, 1)
      })
      return (val = str.join(' '))
    }
  },
  created() {
    this.loggedIn = JSON.parse(localStorage.getItem('logged_in'))
    this.currentUserName = JSON.parse(localStorage.getItem('tokenData'))?.name
  }
}
</script>
<style lang="scss">
@import '@/styles/helpCenter/helpCenterHeader';
</style>
